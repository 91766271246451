<template>
  <div style="display:inline-block">
      <span v-if="isShow">竞价结束</span>
      <span v-else style="color:black"><span v-if="data">{{data.min}}:{{data.second}}</span> </span>
  </div>

</template>
<script>
import tool from '../utils/tool/tool'
import Bus from '../utils/bus'
 export default {
  data () {
   return {
       data:'',
       isShow:false
   }
  },
  props: {
   time:{
       type:String
   }
  },
  created() {
    tool(this.getCountDown,this.time)  
  },
  mounted() {
    // Bus.$emit('countLoadingOver')
  },
  methods: {
      getCountDown(res){
          this.data = res
          if(this.data.day == 0 && this.data.hour === '00' && this.data.min === '00' && this.data.second == '00'){
            this.isShow = true
            // console.log(this.isShow)
          }
          // console.log(this.data)
      }
  },
  destroyed() {
  }
 }
</script>